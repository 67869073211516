<template>
  <div class="buttonBox">
    <button v-on:click="onPush">{{name}}</button>
    <img :src="buttonState ? onImgSrc : offImgSrc">
  </div>
</template>

<script>
export default {
  name: 'PushButton',
  props: {
    name: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      buttonState: false,
      onImgSrc: require('../assets/pushButtonOn.png'),
      offImgSrc: require('../assets/pushButtonOff.png')
    }
  },
  methods: {
    onPush: function (event) {
      this.buttonState = !this.buttonState
      this.$emit('onPush', this.buttonState)
    }
  }
}
</script>

<style scoped>
  button {
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    color: #FFFFFF;
    background-color: gray;
    border: none;
    transition-duration: 0.1s;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

img{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  }

</style>
