<template>
  <div class="stickBox" :class="isOn ? 'up' : 'down'">
    <img class="imgStick" src="../assets/drumStick.png">
  </div>
</template>

<script>
export default {
  name: 'DrumStick',
  props: {
    started: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isOn: this.started
    }
  },
  watch: {
    started: function (newVal) {
      this.isOn = this.started
    }
  }
}
</script>

<style scoped>
div {
  top: 0%;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
}

.imgStick{
  position: absolute;
  display: flex;
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  margin: auto;
}

.down {
  transform: rotate(9deg);
  transform-origin: center left;
}

.up {
  transform: rotate(0deg);
  transform-origin: center left;
}

</style>
