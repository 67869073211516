<template>
  <div class="ledBox" :class="isOn ? 'blink' : 'noblink'">
    <img width="100%" height="auto" src="@/assets/ledDrum.png">
  </div>
</template>

<script>
export default {
  name: 'DrumLight',
  props: {
    started: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isOn: this.started
    }
  },
  watch: {
    started: function (newVal) {
      this.isOn = this.started
      if (this.isOn) {
        console.log('hey')
        this.$emit('onStarted', this.started)
      }
    }
  }
}
</script>

<style scoped>
div {
  position: absolute;
  width: 100%;
  height: auto;
  align-content: center;
  top: -5px;
  opacity: 0;
}

.blink {
  opacity: 0.9;
}

</style>
