<template>
  <div class="telharmonium">
    <modal v-show="isModalVisible" @close="closeModal">
      <p>Pour une meilleure expérience, il est suggéré d'utiliser l'application sur un ordinateur.</p>
    </modal>
      <div class="section fp-auto-height">
        <div class="titleContainer">
          <div class="headerSection">
            <h1>TELHARMONIUM 2.0</h1>
            <p>Une application sonore interactive du duo <em>Ménard St-Amand</em></p>
          </div>
          <div class="spacer"></div>
          <div class="videoSection">
            <video class="videoParent" controls poster="@/assets/telharmoniumThumbnail.png">
              <source :src="this.VIDEO_PATH" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
      <div class="instruction">
      <h1>TELHARMONIUM WEB</h1>
      <p>
        Voici la version <em>application web</em> de notre installation sonore Telharmonium 2.0. Vous pouvez interagir avec elle en utilisant les différents boutons et potentiomètres. Ensuite, vous pouvez composer une courte pièce grâce au séquenceur en bas de la page.
      </p>
    </div>
      <div class="section">
        <div class="subtitle"><h2>Module de la basse</h2></div>
        <div class="bassContainer">
          <img class="bassImgParent" src="@/assets/bassModule.png">
          <div class="ledBass1">
            <led-light :started="this.ledState[3]" :rate="this.bassLedRate"/>
          </div>
          <div class="bassBottom">
          <div class="pushButtonBass1">
            <push-button @onPush="onPush(3, ...arguments)"/>
          </div>
          <div class="knobBass1">
            <log-rate-knob id="BK1" :width="this.knobWidth" :min-value="BASS_RATE_MIN" :max-value="BASS_RATE_MAX" @onRate="onRate(3, ...arguments)" @onValue="onValue(3, ...arguments)"/>
          </div>
          <div class="lineModule1">
            <img class="lineModuleImg1" src="@/assets/lineModule.png">
          </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="subtitle"><h2>Module du drum</h2></div>
        <div class="drumContainer">
          <img class="drumImgParent" src="@/assets/drumModule.png">
          <div class="topStepMotor1">
            <img width="100%" height="100%" src="@/assets/topStepMotor.png">
          </div>
          <div class="stickDrum1">
            <drum-stick :started="this.stickState[0]"/>
          </div>
          <div class="ledDrum1">
            <drum-light :started="this.drumLightState[0]" @onStarted="onDrumHit(0, ...arguments)"/>
          </div>
          <div class="pushButtonDrum1">
            <push-button @onPush="onPush(4, ...arguments)"/>
          </div>
          <div class="knobDrum1">
            <log-rate-knob id="DK1" :width="this.knobWidth" :min-value="DRUM_RATE_MIN" :max-value="DRUM_RATE_MAX" @onRate="onRate(4, ...arguments)" @onValue="onValue(4, ...arguments)"/>
          </div>
          <div class="topStepMotor2">
            <img width="100%" height="100%" src="@/assets/topStepMotor.png">
          </div>
          <div class="stickDrum2">
            <drum-stick :started="this.stickState[1]"/>
          </div>
          <div class="ledDrum2">
            <drum-light :started="this.drumLightState[1]" @onStarted="onDrumHit(1, ...arguments)"/>
          </div>
          <div class="pushButtonDrum2">
            <push-button @onPush="onPush(5, ...arguments)"/>
          </div>
          <div class="knobDrum2">
            <log-rate-knob id="DK2" :width="this.knobWidth" :min-value="DRUM_RATE_MIN" :max-value="DRUM_RATE_MAX" @onRate="onRate(5, ...arguments)" @onValue="onValue(5, ...arguments)"/>
          </div>
          <div class="topStepMotor3">
            <img width="100%" height="100%" src="@/assets/topStepMotor.png">
          </div>
          <div class="stickDrum3">
            <drum-stick :started="this.stickState[2]" />
          </div>
          <div class="ledDrum3">
            <drum-light :started="this.drumLightState[2]" @onStarted="onDrumHit(2, ...arguments)"/>
          </div>
          <div class="pushButtonDrum3">
            <push-button @onPush="onPush(6, ...arguments)"/>
          </div>
          <div class="knobDrum3">
            <log-rate-knob id="DK3" :width="this.knobWidth" :min-value="DRUM_RATE_MIN" :max-value="DRUM_RATE_MAX" @onRate="onRate(6, ...arguments)" @onValue="onValue(6, ...arguments)"/>
          </div>
          <div class="lineModule2">
            <img class="lineModuleImg2" src="@/assets/lineModule.png">
          </div>
        </div>
      </div>
      <div class="section">
        <div class="subtitle"><h2>Module de la mélodie</h2></div>
        <div class="melodyContainer">
          <img class="melodyImgParent" src="@/assets/melodyModule.png">
          <div class="ledMelody1">
            <led-light :started="this.ledState[0]" :rate="this.melodyLedRates[0]"/>
          </div>
          <div class="pushButtonMelody1">
            <push-button @onPush="onPush(0, ...arguments)"/>
          </div>
          <div class="knobMelody1">
            <log-rate-knob id="MK1" :width="this.knobWidth" :min-value="MELODY_RATE_MIN" :max-value="MELODY_RATE_MAX" @onRate="onRate(0, ...arguments)" @onValue="onValue(0, ...arguments)"/>
          </div>
          <div class="ledMelody2">
            <led-light :started="this.ledState[1]" :rate="this.melodyLedRates[1]"/>
          </div>
          <div class="pushButtonMelody2">
            <push-button @onPush="onPush(1, ...arguments)"/>
          </div>
          <div class="knobMelody2">
            <log-rate-knob id="MK2" :width="this.knobWidth" :min-value="MELODY_RATE_MIN" :max-value="MELODY_RATE_MAX" @onRate="onRate(1, ...arguments)" @onValue="onValue(1, ...arguments)"/>
          </div>
          <div class="ledMelody3">
            <led-light :started="this.ledState[2]" :rate="this.melodyLedRates[2]"/>
          </div>
          <div class="pushButtonMelody3">
            <push-button @onPush="onPush(2, ...arguments)"/>
          </div>
          <div class="knobMelody3">
            <log-rate-knob id="MK3" :width="this.knobWidth" :min-value="MELODY_RATE_MIN" :max-value="MELODY_RATE_MAX" @onRate="onRate(2, ...arguments)" @onValue="onValue(2, ...arguments)"/>
          </div>
          <div class="lineModule3">
            <img class="lineModuleImg3" src="@/assets/lineModule.png">
          </div>
        </div>
      </div>
      <div class="sectionSequenceur">
        <SequenceurTel />
      </div>
      </div>
</template>

<script>
// @ is an alias to /src
import LogRateKnob from '@/components/LogRateKnob.vue'
import PushButton from '@/components/PushButton.vue'
import LedLight from '@/components/LedLight.vue'
import DrumStick from '@/components/DrumStick.vue'
import DrumLight from '@/components/DrumLight.vue'
import Modal from '@/components/Modal.vue'
import { version } from '@/../package.json'

import telharmonium from '../lib/telharmonium.js'
import SequenceurTel from './SequenceurTel.vue'

export default {
  name: 'Telharmonium',
  components: {
    LogRateKnob,
    PushButton,
    LedLight,
    DrumStick,
    DrumLight,
    Modal,
    SequenceurTel
  },
  data () {
    return {
      appVersion: version,
      windowWidth: window.innerWidth,
      isModalVisible: false,
      howls: [],
      pbState: [false, false, false, false, false, false, false],
      pbRate: [1, 1, 1, 1, 500, 500, 500],
      mutes: [false, false, false, false, false, false, false],
      stickState: [false, false, false],
      drumLightState: [false, false, false],
      fullpageOptions: {
        licenseKey: '8A5642DC-B4AA4213-88810EF2-9B4EBD44',
        navigation: false,
        loopBottom: true,
        responsiveWidth: 1280,
        paddingBottom: '10px'
      }
    }
  },
  computed: {
    melodyLedRates () {
      return this.pbRate.slice(0, 3).map(x => (x / (this.MELODY_RATE_MAX - this.MELODY_RATE_MIN)) * (this.MELODY_LED_RATE_MIN - this.MELODY_LED_RATE_MAX) + this.MELODY_LED_RATE_MAX)
    },
    bassLedRate () {
      return (this.pbRate[3] / (this.BASS_RATE_MAX - this.BASS_RATE_MIN)) * (this.BASS_LED_RATE_MIN - this.BASS_LED_RATE_MAX) + this.BASS_LED_RATE_MAX
    },
    ledState () {
      const states = []
      for (const [index, element] of this.pbState.slice(0, 4).entries()) {
        states.push(element && !this.mutes[index])
      }
      return states
    },
    knobWidth () {
      if (this.windowWidth >= 1800) {
        return this.KNOB_WIDTH_DESKTOP
      } else if (this.windowWidth >= 1280) {
        return this.KNOB_WIDTH_LAPTOP
      } else if (this.windowWidth >= 764) {
        return this.KNOB_WIDTH_TABLET
      } else {
        return this.KNOB_WIDTH_MOBILE
      }
    }
  },
  methods: {
    onPush: function (index, value) {
      this.$set(this.pbState, index, value)
      if (index <= 3) {
        this.howls[index].toggle(value, this.pbRate[index])
      } else {
        this.toggleDrum(index)
        this.howls[index].toggle(value, this.pbRate[index])
      }
    },
    onRate: function (index, value) {
      this.$set(this.pbRate, index, value)
      this.howls[index].rate = this.pbRate[index]
    },
    onValue: function (index, value) {
      if (value === 0) {
        this.$set(this.mutes, index, true)
        this.howls[index].fade(0.8, 0.0, 100)
      } else {
        if (this.mutes[index]) {
          this.$set(this.mutes, index, false)
          this.howls[index].fade(0.0, 0.8, 100)
        }
      }
    },
    onDrumHit: function (index, value) {
      const timeout = (this.pbRate[index + 4] / 4) >= 100 ? 100 : this.pbRate[index + 4] / 4
      setTimeout(this.setDrumLightState, timeout, index, false)
    },
    toggleDrum: function (index) {
      if (this.pbState[index]) {
        setTimeout(this.raiseStick, this.pbRate[index] / 1.25, index)
        setTimeout(this.hitDrum, this.pbRate[index], index)
      }
    },
    hitDrum: function (index) {
      if (!this.mutes[index]) {
        if (this.stickState[index - 4]) { // To prevent odd behaviors when de-muting
          this.setDrumLightState(index - 4, true)
          // this.howls[index].play()
        }
      }
      this.$set(this.stickState, index - 4, false)
      this.toggleDrum(index)
    },
    raiseStick: function (index) {
      if (!this.mutes[index]) {
        this.$set(this.stickState, index - 4, true)
      }
    },
    setHowlRate: function (index) {
      this.telharmonium.changeRate(this.howls[index], this.pbRate[index])
    },
    setDrumLightState: function (index, value) {
      this.$set(this.drumLightState, index, value)
    },
    showModal: function () {
      this.isModalVisible = true
      document.body.style.position = 'fixed'
    },
    closeModal: function () {
      this.isModalVisible = false
      document.body.style.position = ''
    }
  },
  created: function () {
    this.AWS_BUCKET = 'https://telharmonium-web.s3.us-east-2.amazonaws.com'
    this.VIDEO_PATH = this.AWS_BUCKET + '/TelharmoniumVideo.mp4'
    this.MELODY_LED_RATE_MIN = 20
    this.MELODY_LED_RATE_MAX = 200
    this.BASS_LED_RATE_MIN = 50
    this.BASS_LED_RATE_MAX = 150
    this.DRUM_HIT_RATE_MIN = 50
    this.DRUM_HIT_RATE_MAX = 150
    this.MELODY_RATE_MIN = 0.5
    this.MELODY_RATE_MAX = 2
    this.BASS_RATE_MIN = 0.5
    this.BASS_RATE_MAX = 1.5
    this.DRUM_RATE_MIN = 1000
    this.DRUM_RATE_MAX = 100
    this.KNOB_WIDTH_DESKTOP = 110
    this.KNOB_WIDTH_LAPTOP = 63
    this.KNOB_WIDTH_TABLET = 55
    this.KNOB_WIDTH_MOBILE = 28

    // INDEXES
    this.telharmonium = telharmonium
    this.howls = this.telharmonium.howls
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (window.innerWidth <= 1080) {
      this.showModal()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subtitle{
  padding-bottom: 30px;
}
#MK1{
  width: 150;
}
.instruction{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .instruction p{
    line-height: 2rem;
    text-align: left;
    font-size: 1.2rem;
  }
/*CSS FOR DESKTOP*/
@media only screen and (min-width: 1800px){
h1 {
  color: #575757;
  letter-spacing: 0.2em;
  font-size: 2em;
  margin-top: 55px;
}
p {
  color: #575757;
  font-family: Helvetica;
  font-size: 1.5em;
  letter-spacing: 0.1em;
}
.menuSection{
  background-color: #575757;
  color: #ededed;
  margin: 2px;
  font-family: Helvetica;
}
.moduleSection{
  border: 1px solid red;
  width: 1440px;
  display: inline-block;
}
.headerSection{
  text-align: center;
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.spacer{
  width: 500px;
  height: 75px;
  position: relative;
  display: flex;
}
/*CSS for bass module container*/
.bassContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 684px;
  width: 957px;
  position: relative;
  margin-top: 150px;
}
.bassImgParent{
  position: relative;
  width: 957px;
  height: 684px;
}
.ledBass1{
  position: absolute;
  top: 81px;
  text-align: center;
  left: 454px;
  width: 50px;
  height: 100px;
}
.pushButtonBass1{
  position: absolute;
  top: 490px;
  text-align: center;
  align-content: center;
  left: 330px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.knobBass1{
  position: absolute;
  top: 462px;
  text-align: center;
  align-content: center;
  left: 515px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.lineModule1{
  position: absolute;
  width: 1500px;
  height: 10px;
  top: 670px;
  left: -180px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg1{
  width: 1000px;
  height: 2px;
}
/*CSS for drum module container*/
.drumContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 789px;
  width: 1755px;
  position: relative;
  margin-top: 150px;
}
.drumImgParent{
  position: relative;
  width: 1750px;
  height: 786px;
}
.topStepMotor1{
  position: absolute;
  top: 23px;
  text-align: center;
  left: 166px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum1{
  position: absolute;
  top: 75px;
  left: 234px;
  width: 260px;
  height: 18px;
}
.topStepMotor2{
  position: absolute;
  top: 170px;
  text-align: center;
  left: 697px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum2{
  position: absolute;
  top: 222px;
  text-align: center;
  left: 760px;
  width: 260px;
  height: 18px;
}
.topStepMotor3{
  position: absolute;
  top: 93px;
  text-align: center;
  left: 1226px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum3{
  position: absolute;
  top: 145px;
  text-align: center;
  left: 1290px;
  width: 260px;
  height: 18px;
}
.ledDrum1{
  position: absolute;
  top: 135px;
  text-align: center;
  left: 391px;
  width: 150px;
  height: 20px;
}
.ledDrum2{
  position: absolute;
  top: 280px;
  text-align: center;
  left: 921px;
  width: 150px;
  height: 20px;
}
.ledDrum3{
  position: absolute;
  top: 204px;
  text-align: center;
  left: 1451px;
  width: 150px;
  height: 20px;
}
.pushButtonDrum1{
  position: absolute;
  top: 595px;
  text-align: center;
  left: 198px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.pushButtonDrum2{
  position: absolute;
  top: 595px;
  text-align: center;
  left: 730px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.pushButtonDrum3{
  position: absolute;
  top: 595px;
  text-align: center;
  left: 1260px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.knobDrum1{
  position: absolute;
  top: 571px;
  text-align: center;
  left: 350px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.knobDrum2{
  position: absolute;
  top: 571px;
  text-align: center;
  left: 875px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.knobDrum3{
  position: absolute;
  top: 571px;
  text-align: center;
  left: 1420px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.lineModule2{
  position: absolute;
  width: 1400px;
  height: 10px;
  top: 775px;
  left: 100px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg2{
  width: 1300px;
  height: 3px;
}
/*CSS for melody module container*/
.melodyContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 735px;
  width: 1437px;
  position: relative;
}
.melodyImgParent{
  position: relative;
  width: 1437px;
  height: 735px;
}
.ledMelody1{
  position: absolute;
  top: 80px;
  text-align: center;
  left: 355px;
  width: 45px;
  height: 140px;
}
.ledMelody2{
  position: absolute;
  top: 230px;
  text-align: center;
  left: 697px;
  width: 45px;
  height: 100px;
}
.ledMelody3{
  position: absolute;
  top: 150px;
  text-align: center;
  left: 1042px;
  width: 45px;
  height: 100px;
}
.pushButtonMelody1{
  position: absolute;
  top: 550px;
  text-align: center;
  left: 250px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.pushButtonMelody2{
  position: absolute;
  top: 550px;
  text-align: center;
  left: 610px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.pushButtonMelody3{
  position: absolute;
  top: 550px;
  text-align: center;
  left: 950px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.knobMelody1{
  position: absolute;
  top: 525px;
  text-align: center;
  left: 400px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.knobMelody2{
  position: absolute;
  top: 525px;
  text-align: center;
  left: 750px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.knobMelody3{
  position: absolute;
  top: 525px;
  text-align: center;
  left: 1100px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.lineModule3{
  position: absolute;
  width: 1300px;
  height: 10px;
  top: 722px;
  left: 100px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg3{
  width: 1300px;
  height: 2px;
}
.videoSection{
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 1351px;
  height: 747px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.videoParent{
  position: relative;
  width: 100%;
  height: auto;
}
.textAbout{
  position: relative;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  top: -50px;
  line-height: 40px;
  font-size: 1.5rem;
}
.textAbout p{
  color: #575757;
}
.textAbout a{
  color: #575757;
}
ul{
  list-style-type: none;
}
.section {
  padding-bottom: 100px;
  padding-top: 100px;
}
}
/*CSS for laptop */
@media only screen and (min-width: 1280px) and (max-width: 1800px){
h1 {
  color: #575757;
  letter-spacing: 0.2em;
  font-size: 1.5em;
  padding-top: 30px;
}
p {
  color: #575757;
  font-family: Helvetica;
  font-size: 1em;
  letter-spacing: 0.1em;
}
.menuSection{
  background-color: #6b6d6e;
  color: #ededed;
  margin: 2px;
  font-family: Helvetica;
}
.moduleSection{
  width: 1440px;
  display: inline-block;
}
.headerSection{
  text-align: center;
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.spacer{
  width: 500px;
  height: 0px;
  position: relative;
  display: flex;
}
/*CSS for bass module container*/
.bassContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 456px;
  width: 638px;
  position: relative;
}
.bassImgParent{
  position: relative;
  width: 638px;
  height: 456px;
}
.ledBass1{
  position: absolute;
  top: 58px;
  text-align: center;
  left: 304px;
  width: 29px;
  height: 100px;
}
.pushButtonBass1{
  position: absolute;
  top: 327px;
  text-align: center;
  align-content: center;
  left: 244px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.knobBass1{
  position: absolute;
  top: 313px;
  text-align: center;
  align-content: center;
  left: 335px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.lineModule1{
  position: absolute;
  width: 1000px;
  height: 10px;
  top: 442px;
  left: -200px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg1{
  width: 1000px;
  height: 2px;
}
/*CSS for drum module container*/
.drumContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
  height: 526px;
  width: 1170px;
  position: relative;
}
.drumImgParent{
  position: relative;
  width: 1170px;
  height: 526px;
}
.topStepMotor1{
  position: absolute;
  top: 12px;
  text-align: center;
  left: 106px;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum1{
  position: absolute;
  top: 45px;
  left: 139px;
  width: 189px;
  height: 18px;
}
.topStepMotor2{
  position: absolute;
  top: 110px;
  text-align: center;
  left: 462px;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum2{
  position: absolute;
  top: 145px;
  text-align: center;
  left: 502px;
  width: 180px;
  height: 18px;
}
.topStepMotor3{
  position: absolute;
  top: 58px;
  text-align: center;
  left: 815px;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum3{
  position: absolute;
  top: 93px;
  text-align: center;
  left: 862px;
  width: 176px;
  height: 18px;
}
.ledDrum1{
  position: absolute;
  top: 90px;
  text-align: center;
  left: 260px;
  width: 100px;
  height: 10px;
}
.ledDrum2{
  position: absolute;
  top: 188px;
  text-align: center;
  left: 614px;
  width: 100px;
  height: 10px;
}
.ledDrum3{
  position: absolute;
  top: 135px;
  text-align: center;
  left: 968px;
  width: 100px;
  height: 10px;
}
.pushButtonDrum1{
  position: absolute;
  top: 410px;
  text-align: center;
  left: 128px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.pushButtonDrum2{
  position: absolute;
  top: 410px;
  text-align: center;
  left: 490px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.pushButtonDrum3{
  position: absolute;
  top: 410px;
  text-align: center;
  left: 850px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.knobDrum1{
  position: absolute;
  top: 393px;
  text-align: center;
  left: 219px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.knobDrum2{
  position: absolute;
  top: 393px;
  text-align: center;
  left: 593px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.knobDrum3{
  position: absolute;
  top: 393px;
  text-align: center;
  left: 948px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.lineModule2{
  position: absolute;
  width: 1400px;
  height: 10px;
  top: 513px;
  left: -120px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg2{
  width: 1300px;
  height: 3px;
}
.section{
  padding-bottom: 100px;
  padding-top: 100px;
}

/*CSS for melody module container*/
.melodyContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 490px;
  width: 958px;
  position: relative;
}
.melodyImgParent{
  position: relative;
  width: 958px;
  height: 490px;
}
.ledMelody1{
  position: absolute;
  top: 53px;
  text-align: center;
  left: 236px;
  width: 29px;
  height: 100px;
}
.ledMelody2{
  position: absolute;
  top: 151px;
  text-align: center;
  left: 465px;
  width: 29px;
  height: 100px;
}
.ledMelody3{
  position: absolute;
  top: 100px;
  text-align: center;
  left: 695px;
  width: 29px;
  height: 100px;
}
.pushButtonMelody1{
  position: absolute;
  top: 370px;
  text-align: center;
  left: 169px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.pushButtonMelody2{
  position: absolute;
  top: 370px;
  text-align: center;
  left: 404px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.pushButtonMelody3{
  position: absolute;
  top: 370px;
  text-align: center;
  left: 640px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.knobMelody1{
  position: absolute;
  top: 355px;
  text-align: center;
  left: 260px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.knobMelody2{
  position: absolute;
  top: 355px;
  text-align: center;
  left: 495px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.knobMelody3{
  position: absolute;
  top: 355px;
  text-align: center;
  left: 732px;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.lineModule3{
  position: absolute;
  width: 1000px;
  height: 10px;
  top: 477px;
  left: -150px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg3{
  width: 1300px;
  height: 2px;
}
.videoSection{
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 810px;
  height: 448px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.videoParent{
  position: relative;
  width: 100%;
  height: auto;
}
.textAbout{
  position: relative;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  top: -50px;
  line-height: 25px;
}
.textAbout p{
  color: #575757;
}
.textAbout a{
  color: #575757;
}
ul{
  list-style-type: none;
}
}
/*CSS FOR TABLET*/
@media only screen and (min-width: 764px) and (max-width: 1280px){
h1 {
  color: #575757;
  font-family: Helvetica;
  letter-spacing: 0.3em;
  font-size: 1.5em;
  padding-top: 30px;
}
p {
  color: #575757;
  font-family: Helvetica;
  font-size: 1.2em;
}
.headerSection{
  display: inline-block;
  text-align: center;
  width: 600px;
  height: 100px;
}
.spacer{
  width: 500px;
  height: 50px;
  position: relative;
  display: flex;
}
/*CSS for bass module container*/
.bassContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 256px;
  width: 357px;
  position: relative;
  margin-top: 150px;
}
.bassImgParent{
  position: relative;
  width: 357px;
  height: 256px;
}
.ledBass1{
  position: absolute;
  top: 30px;
  text-align: center;
  left: 170px;
  width: 15px;
  height: 45px;
}
.pushButtonBass1{
  position: absolute;
  top: 180px;
  text-align: center;
  left: 120px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobBass1{
  position: absolute;
  top: 165px;
  text-align: center;
  left: 190px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.lineModule1{
  position: absolute;
  width: 500px;
  height: 10px;
  top: 245px;
  left: -65px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg1{
  width: 500px;
  height: 2px;
}
/*CSS for drum module container*/
.drumContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 295px;
  width: 656px;
  position: relative;
  margin-top: 150px;
}
.drumImgParent{
  position: relative;
  width: 656px;
  height: 295px;
}
.topStepMotor1{
  position: absolute;
  top: 8px;
  text-align: center;
  left: 61px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum1{
  position: absolute;
  top: 27px;
  text-align: center;
  left: 85px;
  width: 100px;
  height: 8px;
}
.topStepMotor2{
  position: absolute;
  top: 63px;
  text-align: center;
  left: 260px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum2{
  position: absolute;
  top: 82px;
  text-align: center;
  left: 285px;
  width: 100px;
  height: 8px;
}
.topStepMotor3{
  position: absolute;
  top: 34px;
  text-align: center;
  left: 459px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 2;
}
.stickDrum3{
  position: absolute;
  top: 54px;
  text-align: center;
  left: 482px;
  width: 100px;
  height: 8px;
}
.ledDrum1{
  position: absolute;
  top: 45px;
  text-align: center;
  left: 146px;
  width: 55px;
  height: 5px;
}
.ledDrum2{
  position: absolute;
  top: 101px;
  text-align: center;
  left: 345px;
  width: 55px;
  height: 5px;
}
.ledDrum3{
  position: absolute;
  top: 72px;
  text-align: center;
  left: 544px;
  width: 56px;
  height: 5px;
}
.pushButtonDrum1{
  position: absolute;
  top: 220px;
  text-align: center;
  left: 70px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.pushButtonDrum2{
  position: absolute;
  top: 220px;
  text-align: center;
  left: 270px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.pushButtonDrum3{
  position: absolute;
  top: 220px;
  text-align: center;
  left: 465px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobDrum1{
  position: absolute;
  top: 205px;
  text-align: center;
  left: 130px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.knobDrum2{
  position: absolute;
  top: 205px;
  text-align: center;
  left: 330px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.knobDrum3{
  position: absolute;
  top: 205px;
  text-align: center;
  left: 525px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.lineModule2{
  position: absolute;
  width: 800px;
  height: 10px;
  top: 284px;
  left: -65px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg2{
  width: 800px;
  height: 2px;
}
/*CSS for melody module container*/
.melodyContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 288px;
  width: 536px;
  position: relative;
  margin-top: 150px;
}
.melodyImgParent{
  position: relative;
  width: 536px;
  height: 288px;
}
.ledMelody1{
  position: absolute;
  top: 31px;
  text-align: center;
  left: 132px;
  width: 15px;
  height: 55px;
}
.ledMelody2{
  position: absolute;
  top: 88px;
  text-align: center;
  left: 260px;
  width: 15px;
  height: 55px;
}
.ledMelody3{
  position: absolute;
  top: 58px;
  text-align: center;
  left: 389px;
  width: 15px;
  height: 55px;
}
.pushButtonMelody1{
  position: absolute;
  top: 212px;
  text-align: center;
  left: 87px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.pushButtonMelody2{
  position: absolute;
  top: 212px;
  text-align: center;
  left: 223px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.pushButtonMelody3{
  position: absolute;
  top: 212px;
  text-align: center;
  left: 353px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobMelody1{
  position: absolute;
  top: 198px;
  text-align: center;
  left: 140px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.knobMelody2{
  position: absolute;
  top: 198px;
  text-align: center;
  left: 280px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.knobMelody3{
  position: absolute;
  top: 198px;
  text-align: center;
  left: 410px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.lineModule3{
  position: absolute;
  width: 800px;
  height: 10px;
  top: 275px;
  left: -110px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg3{
  width: 800px;
  height: 2px;
}
button{
  width: 5em;
  height: 5em;
  border-radius: 50%;
}
.videoSection{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 533px;
  height: 308px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.videoParent{
  position: relative;
  width: 100%;
  width: 100;
}
.textAbout{
  position: relative;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  top: -50px;
}
.textAbout p{
  color: #575757;
}
.textAbout a{
  color: #575757;
}
ul{
  list-style-type: none;
}

}
/*CSS for mobile */
@media only screen and (min-width: 300px) and (max-width: 764px){
h1 {
  color: #575757;
  font-family: Helvetica;
  letter-spacing: 0.2em;
  font-size: 1rem;
  padding-top: 90px;
  padding-bottom: 10px
}
p {
  color: #575757;
  font-family: Helvetica;
  font-size: 0.8em;
}
.menuSection{
  background-color: #6b6d6e;
  color: #ededed;
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.headerSection{
  display: inline-block;
  text-align: center;
  width: 400px;
  height: 100px;
}
.spacer{
  width: 300px;
  height: 100px;
  position: relative;
  display: flex;
}
/*CSS for bass module container*/
.bassContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 160px;
  width: 223px;
  position: relative;
}
.bassImgParent{
  position: relative;
  width: 223px;
  height: 160px;
}
.ledBass1{
  position: absolute;
  top: 19px;
  text-align: center;
  left: 105px;
  width: 12px;
  height: 44px;
}
.pushButtonBass1{
  position: absolute;
  top: 113px;
  text-align: center;
  left: 83px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.knobBass1{
  position: absolute;
  top: 106px;
  text-align: center;
  left: 119px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.lineModule1{
  position: absolute;
  width: 300px;
  height: 10px;
  top: 146px;
  left: -41px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg1{
  width: 300px;
  height: 2px;
}
/*CSS for drum module container*/
.drumContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 165px;
  width: 369px;
  position: relative;
}
.drumImgParent{
  position: relative;
  width: 369px;
  height: 165px;
}
.topStepMotor1{
  position: absolute;
  top: 6px;
  text-align: center;
  left: 36px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 5;
}
.stickDrum1{
  position: absolute;
  top: 16px;
  text-align: center;
  left: 48px;
  width: 55px;
  height: 8px;
}
.topStepMotor2{
  position: absolute;
  top: 36px;
  text-align: center;
  left: 147px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: 5;
}
.stickDrum2{
  position: absolute;
  top: 46px;
  text-align: center;
  left: 161px;
  width: 55px;
  height: 8px;
}
.topStepMotor3{
  position: absolute;
  top: 20px;
  text-align: center;
  left: 259px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: 5;
}
.stickDrum3{
  position: absolute;
  top: 30px;
  text-align: center;
  left: 271px;
  width: 55px;
  height: 8px;
}
.ledDrum1{
  position: absolute;
  top: 20px;
  text-align: center;
  left: 82px;
  width: 32px;
  height: 5px;
}
.ledDrum2{
  position: absolute;
  top: 51px;
  text-align: center;
  left: 194px;
  width: 31px;
  height: 5px;
}
.ledDrum3{
  position: absolute;
  top: 35px;
  text-align: center;
  left: 306px;
  width: 31px;
  height: 5px;
}
.pushButtonDrum1{
  position: absolute;
  top: 125px;
  text-align: center;
  left: 38px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.pushButtonDrum2{
  position: absolute;
  top: 125px;
  text-align: center;
  left: 150px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.pushButtonDrum3{
  position: absolute;
  top: 125px;
  text-align: center;
  left: 265px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.knobDrum1{
  position: absolute;
  top: 118px;
  text-align: center;
  left: 80px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobDrum2{
  position: absolute;
  top: 118px;
  text-align: center;
  left: 195px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobDrum3{
  position: absolute;
  top: 118px;
  text-align: center;
  left: 305px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.lineModule2{
  position: absolute;
  width: 400px;
  height: 10px;
  top: 150px;
  left: -15px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg2{
  width: 400px;
  height: 2px;
}
/*CSS for melody module container*/
.melodyContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 162px;
  width: 302px;
  position: relative;
}
.melodyImgParent{
  position: relative;
  width: 302px;
  height: 162px;
}
.ledMelody1{
  position: absolute;
  top: 18px;
  text-align: center;
  left: 74px;
  width: 10px;
  height: 45px;
}
.ledMelody2{
  position: absolute;
  top: 50px;
  text-align: center;
  left: 146px;
  width: 10px;
  height: 45px;
}
.ledMelody3{
  position: absolute;
  top: 33px;
  text-align: center;
  left: 219px;
  width: 10px;
  height: 45px;
}
.pushButtonMelody1{
  position: absolute;
  top: 120px;
  text-align: center;
  left: 47px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.pushButtonMelody2{
  position: absolute;
  top: 120px;
  text-align: center;
  left: 128px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.pushButtonMelody3{
  position: absolute;
  top: 120px;
  text-align: center;
  left: 205px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.knobMelody1{
  position: absolute;
  top: 114px;
  text-align: center;
  left: 82px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobMelody2{
  position: absolute;
  top: 114px;
  text-align: center;
  left: 160px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.knobMelody3{
  position: absolute;
  top: 114px;
  text-align: center;
  left: 235px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.lineModule3{
  position: absolute;
  width: 400px;
  height: 10px;
  top: 148px;
  left: -50px;
  align-content: center;
  text-align: center;
  z-index: 0;
}
.lineModuleImg3{
  width: 400px;
  height: 2px;
}
button{
  width: 5em;
  height: 5em;
  border-radius: 50%;
}
.videoSection{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 227px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.videoParent{
  position: relative;
  width: 100%;
  width: 100;
}
a {
  text-decoration: none;
  color: #fffaf5;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
a :active{
  color: #fffaf5;
}
a :visited{
  color: #fffaf5;
}
.textAbout{
  position: relative;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  top: -170px;
}
.textAbout p{
  color: #575757;
  font-size: 0.9rem;
}
.textAbout a{
  color: #575757;
}
.textAbout h1{
  font-size: 0.8rem;
}
ul{
  list-style-type: none;
}
}

</style>
