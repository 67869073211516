
<template>
<div class="séquenceur">
  <div class="avertissement">
    <h1>Séquenceur pour le Telharmonium 2.0</h1>
    <p>Malheureusement le séquenceur est seulement supporté sur un ordinateur</p>
    </div>
  <main>
    <section>
      <h2>
        Séquenceur pour le Telharmonium Web
      </h2>
      <ul>
        <li>
          Clic gauche sur une cellule pour inclure le son associé dans la séquence.
        </li>
        <li>
          Clic droit sur une cellule pour retirer le son associé de la séquence. (ou clic à deux doigts sur les trackpad Apple)
        </li>
        <li>
          Utiliser la molette ou les touches fléchées sur une cellule pour ajuster la fréquence du son associé.
        </li>
        <li>
          Cliquer sur Play pour lire la séquence.
        </li>
        <li>
          Cliquer sur Stop pour terminer la lecture séquentielle.
        </li>
        <li>
          Lorsque la séquence joue, il n'est pas possible d'ajouter des sons pendant la lecture.
        </li>
        <li>
          Chaque rangée correspond à un moteur associé à l'oeuvre physique.
        </li>
        <li>
          Il est possible de changer la vitesse de lecture grâce aux boutons (+) et (-).
        </li>
      </ul>
      <sequencer v-bind:instrument="telharmonium" />
    </section>
  </main>
</div>
</template>

<script>
import Sequencer from '../components/sequencer/Sequencer.vue'

import telharmonium from '@/lib/telharmonium.js'
import onOff from '@/lib/onOff.js'
import clicks from '@/lib/clicks.js'

export default {
  name: 'SequenceurTel',
  components: {
    Sequencer
  },
  mounted: function () {
    document.onkeydown = function (event) {
      const { key } = event || window.event

      if (['ArrowUp', 'ArrowDown'].includes(key)) {
        return false
      }
    }
  },
  data: function () {
    return {
      telharmonium,
      onOff,
      playClicks: [
        clicks.sounds.CLICK_1_ON,
        clicks.sounds.CLICK_2_ON
      ],
      stopClicks: [
        clicks.sounds.CLICK_1_OFF,
        clicks.sounds.CLICK_2_OFF
      ]
    }
  },
  methods: {
    handlePlay: function (soundId) {
      this.playClicks
        .find(click => click.mappingIds.includes(soundId))
        .click()
    },
    handleStop: function (soundId) {
      this.stopClicks
        .find(click => click.mappingIds.includes(soundId))
        .click()
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 1800px) {
  header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    opacity: 100;
  }
h2 {
  padding: 20px;
  font-size: 3rem;
}
ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 80px;
  font-size: 2rem;
  margin-bottom: 100px;
}
section {
  padding: 40px;
}
.séquenceur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.avertissement{
  opacity: 0;
  position: relative;
  width: 400px;
  height: 100px;
  margin-top: -100px;
}
}
@media only screen and (min-width: 700px) and (max-width: 1080px) {
  header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
  }

ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.séquenceur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
}
.avertissement{
  opacity: 100;
  position: relative;
  width: 400px;
  height: 100px;
  margin-top: 100px;
  z-index: 10;
}
}
@media only screen and (min-width: 1080px) and (max-width: 1800px) {
  header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    opacity: 100;
  }
h2 {
  padding: 20px;
}
ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 30px;
}
section {
  padding: 40px;
}
.séquenceur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avertissement{
  opacity: 0;
  position: relative;
  width: 400px;
  height: 100px;
  margin-top: -100px;
}
}
@media only screen and (min-width: 300px) and (max-width: 700px) {
  header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
  }

ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.séquenceur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
}
.avertissement{
  opacity: 100;
  position: relative;
  width: 400px;
  height: 100px;
  margin-top: 100px;
  z-index: 10;
}
}
</style>
