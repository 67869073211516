<template>
  <div class="ledBox" :class="isOn ? 'blink' : 'noblink'">
    <img width="100%" src="@/assets/ledOff.png">
  </div>
</template>

<script>
export default {
  name: 'LedLight',
  props: {
    started: {
      type: Boolean,
      required: true
    },
    rate: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      blinkRate: this.rate,
      isOn: false
    }
  },
  methods: {
    blink: function () {
      if (this.started) {
        this.isOn = !this.isOn
        setTimeout(this.blink, this.blinkRate)
      } else {
        this.isOn = false
      }
    }
  },
  watch: {
    started: function (newVal) {
      this.blink()
    },
    rate: function (newVal) {
      this.blinkRate = this.rate
    }
  }
}
</script>

<style scoped>
div {
  position: absolute;
  width: 100%;
  align-content: center;
}

.blink {
  opacity: 1.0;
}

.noblink {
  opacity: 0.4
}

</style>
