<template>
  <div>
    <input
      :id="this.id"
      :key="this.key"
      type="range"
      class="input-knob"
      min="0"
      max="100"
      step="0.1"
      :data-diameter="this.width"
      :data-src="this.imgsrc"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: 'LogRateKnob',
  props: {
    id: {
      type: String,
      required: true
    },
    minValue: {
      type: Number,
      default: 0.5
    },
    maxValue: {
      type: Number,
      default: 1.5
    },
    value: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 25
    }
  },
  data () {
    return {
      sliderValue: this.value,
      imgsrc: require('../assets/knob.png'),
      key: 0
    }
  },
  computed: {
    rate () {
      const minv = Math.log(this.minValue)
      const maxv = Math.log(this.maxValue)
      const scale = (maxv - minv) / 100
      return Math.exp(minv + scale * this.sliderValue)
    }
  },
  methods: {
    onInput: function (event) {
      this.sliderValue = parseFloat(event.target.value)
    }
  },
  watch: {
    width: function (val) {
      this.key += 1 // This is just a hack to force the re-rendering of the knob on resize
    },
    sliderValue: function (val) {
      let deg = 0
      if (val >= 50) {
        deg = (val / (100 - 50)) * (135 - 1) - 135
      } else {
        deg = (val / 49) * (-1 + 135) - 135
      }
      document.getElementById(this.id).style.transform = `rotate(${deg}deg)`
      this.$emit('onValue', this.sliderValue)
    },
    rate: function (val) {
      this.$emit('onRate', this.rate, { immediate: true })
    }
  }
}
</script>

<style scoped>
.input-knob{
  position: absolute;
  display: flex;
  width: 100%;
}
input:focus {
    outline:none;
}

</style>
